import React, { Component } from 'react'
import Header from '../layout/Header';
import Banner from '../Common/Banner';
import { doc, getDoc } from 'firebase/firestore';
import { firestore } from '../../Config/FbConfig';

export default class AboutManagement extends Component {

    state = {
        PresidentName: "",
        PresidentDesignation: "",
        PresidentImage: "",
        MainDatas: [],
        ECDatas: [],
        GCDatas: [],
        Loading: true
    }

    //Load Data Here
    async componentDidMount() {

        //Get President Data
        const docRef = doc(firestore, "Other", "RVSPresident");
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            Object.entries(docSnap.data()).forEach(([key, value]) => {
                this.setState({
                    PresidentName: value.Name,
                    PresidentDesignation: value.Designation,
                    PresidentImage: value.Image,
                })
            });
        }

        //Get Main Member Data
        const docRef_1 = doc(firestore, "Other", "MainMembers");
        const docSnap_1 = await getDoc(docRef_1);
        var Data_1 = []
        if (docSnap_1.exists()) {
            Object.entries(docSnap_1.data()).forEach(([key, value]) => {
                Data_1.push(Object.assign({ Id: key, Priority: value.Priority != undefined ? value.Priority : "999" }, value))
            });

            Data_1.length === 0 ?
                this.setState({ MainDatas: "NO_999", Loading: false }) :
                this.setState({ MainDatas: Data_1.sort(function (a, b) { return a.Priority - b.Priority; }), Loading: false })
        }

        //Get EC Member Data
        const docRef_2 = doc(firestore, "Other", "ECMembers");
        const docSnap_2 = await getDoc(docRef_2);
        var Data_2 = []
        if (docSnap_2.exists()) {
            Object.entries(docSnap_2.data()).forEach(([key, value]) => {
                Data_2.push(Object.assign({ Id: key, Priority: value.Priority != undefined ? value.Priority : "999" }, value))
            });

            Data_1.length === 0 ?
                this.setState({ ECDatas: "NO_999", Loading: false }) :
                this.setState({ ECDatas: Data_2.sort(function (a, b) { return a.Priority - b.Priority; }), Loading: false })
        }

        //Get GC Member Data
        const docRef_3 = doc(firestore, "Other", "GCMembers");
        const docSnap_3 = await getDoc(docRef_3);
        var Data_3 = []
        if (docSnap_3.exists()) {
            Object.entries(docSnap_3.data()).forEach(([key, value]) => {
                Data_3.push(Object.assign({ Id: key, Priority: value.Priority != undefined ? value.Priority : "999" }, value))
            });

            Data_3.length === 0 ?
                this.setState({ GCDatas: "NO_999", Loading: false }) :
                this.setState({ GCDatas: Data_3.sort(function (a, b) { return a.Priority - b.Priority; }), Loading: false })
        }

    }

    render() {

        return (
            <div className="body_wrapper">
                <Header />
                <Banner title="About Management" image="banners/About.jpg" />

                {/* Content */}
                <div className="paddingtop-2 sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl">
                        <div className="ttm-service-single-content-area">
                            <div className="section-title with-desc text-left clearfix">
                                <div className="title-header" style={{ borderTopColor: "red" }}>
                                    <h4 className="title">History and Objectives of Rajya Vokkaligara Sangha (<a href="http://rvsangha.org/" target="_blank">RVS</a>)</h4>
                                </div>
                            </div>
                            <div className="ttm-service-description">
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="ttm_single_image-wrapper">
                                            <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/management/rvs-1.jpg"} alt="error" />
                                        </div>
                                    </div>
                                    <div className="col-md-8 justify" style={{ margin: "auto" }}>
                                        <p>Rajya Vokkaligara Sangha (<a href="http://rvsangha.org/" target="_blank">RVS</a>) was established in the year 1906 to promote social, cultural and educational aspirations of the agricultural community. Rajya Vokkaligara Sangha with a commanding presence in Bengaluru, Ramanagara, Mandya, Mysuru, Chikmagalur, Hassan, Tumukuru, Chikkaballapura, Kolar and Chitradurga districts and with a fairly well spread presence in other regions of Karnataka, has been conducting number of regular activities for the development and wellbeing of the community in particular and to all sections of the society in general. The sangha has also provided hostel facilities for poor economic background students and students are making use of these hostel facilities during their education career.</p>
                                    </div>
                                    <div className="section-title with-desc text-left clearfix paddingtop-2">
                                        <div className="title-header" style={{ borderTopColor: "red" }}>
                                            <h5 className="title">The main Objectives of RVS are</h5>
                                        </div>
                                    </div>
                                    <div className='col-md-12'>
                                        <ul className="ttm-list ttm-list-style-icon">
                                            <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">To promote educational aspirations of the community by starting educational institutions in various disciples.</span></li>
                                            <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">To encourage, promote and popularize cultural activities.</span></li>
                                            <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">To promote well-being of the community in all sections of society.</span></li>
                                            <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">To improve the living of economically poor community students by setting up free hostel facilities.</span></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="section-title with-desc text-left clearfix paddingtop-2">
                                <div className="title-header" style={{ borderTopColor: "red" }}>
                                    <h4 className="title">Organizational Structure</h4>
                                </div>
                            </div>
                            <div className="row ttm-service-description" style={{ textAlign: "center" }}>
                                <div className='col-md-6 offset-md-3 paddingtop-1'>
                                    <img className="img-fluid" src={this.state.PresidentImage} alt="error" />
                                    <h5 className="paddingtop-1">{this.state.PresidentName}</h5>
                                    <h6>{this.state.PresidentDesignation}</h6>
                                </div>
                                {
                                    this.state.MainDatas.map((data, i) => (
                                        <div className='col-md-4 paddingtop-2' style={{ textAlign: "center" }} key={i}>
                                            <img className="img-fluid" src={data.Image} alt="error" />
                                            <h6 className="paddingtop-1">{data.Name}</h6>
                                            <small>{data.Designation}</small>
                                        </div>
                                    ))
                                }
                            </div>
                            <div class="ttm-service-single-content-area">
                                <div className="section-title with-desc text-left clearfix paddingtop-2">
                                    <div className="title-header" style={{ borderTopColor: "red" }}>
                                        <h4 className="title">Executive Council Members</h4>
                                    </div>
                                </div>
                                <div className="row ttm-service-description" style={{ textAlign: "center" }}>
                                    {
                                        this.state.ECDatas.map((data, i) => (
                                            <div className='col-md-4 paddingtop-2' style={{ textAlign: "center" }}>
                                                <img className="img-fluid" src={data.Image} alt="error" />
                                                <h6 className="paddingtop-1">{data.Name}</h6>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                            <div class="ttm-service-single-content-area">
                                <div className="section-title with-desc text-left clearfix paddingtop-2">
                                    <div className="title-header" style={{ borderTopColor: "red" }}>
                                        <h4 className="title">Governing Council Members</h4>
                                    </div>
                                </div>
                                <div className="row ttm-service-description" style={{ textAlign: "center" }}>
                                    {
                                        this.state.GCDatas.map((data, i) => (
                                            <div className='col-md-4 paddingtop-2' style={{ textAlign: "center" }}>
                                                <img className="img-fluid" src={data.Image} alt="error" />
                                                <h6 className="paddingtop-1">{data.Name}</h6>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                            <br /><br />
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

